import * as React from 'react';
import Helmet from 'react-helmet';
import useSiteMetadata from '../../hooks/data/useSiteMetaData';

type MetaProps = JSX.IntrinsicElements['meta'];

interface ISeoProps {
  title?: string;
  description?: string;
  image?: string;
  lang?: string;
  meta?: MetaProps[];
}

const Seo: React.FC<ISeoProps> = ({
  description,
  lang,
  meta,
  title,
  image,
}) => {
  const siteMetadata = useSiteMetadata();
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={
        title
          ? `${title} | ${String(siteMetadata?.title)}`
          : String(siteMetadata?.title)
      }
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:title`,
          content: String(siteMetadata?.title),
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:image`,
          content: image,
        },
        ...(meta ? meta : []),
      ]}
    />
  );
};

Seo.defaultProps = {
  meta: [],
  description: '',
  lang: 'en',
};

export default Seo;
