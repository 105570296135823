import { graphql, useStaticQuery } from 'gatsby';
import { SiteMetaDataQuery } from '../../../gatsby-graphql';

const useSiteMetadata = () => {
  const data = useStaticQuery<SiteMetaDataQuery>(graphql`
    query SiteMetaData {
      site {
        siteMetadata {
          title
          author
          description
        }
      }
    }
  `);
  return data.site?.siteMetadata;
};

export default useSiteMetadata;
