/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { _window, isBuildTime } from './index';

const getNavigatorLocale = () => {
  if (isBuildTime) return '';
  const firstLang = _window.navigator.languages
    ? _window.navigator.languages[0]
    : null;
  const lang =
    firstLang ||
    _window.navigator.language ||
    // @ts-ignore
    _window.navigator.browserLanguage ||
    // @ts-ignore
    _window.navigator.userLanguage;
  return lang.split('-')[0];
};

export const getBrowserLocaleFromURL = () =>
  !isBuildTime ? _window.location.pathname.split('/')[1] : '';

export const getBrowserLocale = (locales: string[], defaultLocale: string) => {
  const browserLocale = getNavigatorLocale();
  const browserLocaleExists = locales.includes(browserLocale);
  return browserLocaleExists ? browserLocale : defaultLocale;
};

export const getPageLocale = (locales: string[], defaultLocale: string) => {
  const urlLocale = getBrowserLocaleFromURL();
  if (locales.includes(urlLocale)) return urlLocale;
  return getBrowserLocale(locales, defaultLocale);
};

export const updateUrlLocale = (
  currentLocale: string,
  newLocale: string,
  pathname: string
) => pathname.replace(`/${currentLocale}`, `/${newLocale}`);
